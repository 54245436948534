const close = document.querySelector('.banner-guide');
close.addEventListener('click', function () {
  const now = new Date();
  var localStorageData = JSON.stringify({
    expires: now.getTime() + 1000 * 60 * 60, //  + 1 hour
  });
  window.localStorage.setItem('banner-closed', localStorageData);
});
const now = new Date();
const ls = JSON.parse(window.localStorage.getItem('banner-closed'));
if (ls !== null) {
  if (now.getTime() < ls.expires) {
    document.querySelector('.banner-guide').style.display = 'none';
  }
}
